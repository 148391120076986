import React from 'react';

import Navbar from 'react-bootstrap/Navbar';

import Nav from "react-bootstrap/Nav";

import { NavLink } from 'react-router-dom';

import './navbar.css';

import { getAsset } from '../../common/assetMap';

const NavBar = () => {
    return (
        <>
            <Navbar sticky="top" bg="dark" variant="dark" expand="md">
                <div>
                    <Navbar.Brand>
                        <NavLink exact to="/" activeClassName="active">
                            <img className='logo' src={getAsset("logo")} alt="logo" />
                        </NavLink>
                    </Navbar.Brand>
                </div>
                <Navbar.Toggle className="nav-toggle-btn" aria-controls="basic-navbar-nav" />
                <Navbar.Collapse className="nav-right justify-content-end" id="basic-navbar-nav">
                    <Nav className="justify-content-end pr-6 pl-3">
                        <>
                            <NavLink className="nav-link" exact to="/roadmap" activeClassName="active">
                                RoadMap
                            </NavLink>
                        </>
                        <>
                            <NavLink className="nav-link" to="/courses" activeClassName="active">
                                Courses
                            </NavLink>
                        </>
                        <>
                            <NavLink className="nav-link" to="/aboutus" activeClassName="active">
                                About us
                            </NavLink>
                        </>
                        <>
                            <NavLink className="nav-link" to="/" onClick={() => {
                                window.open("https://forms.gle/onftBUbs8xLQCG4d7", "_blank")
                            }} activeClassName="active">
                                Contact
                            </NavLink>
                        </>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    );
};

export default NavBar;