import React from 'react';

import './mentorSection.css'
import Flex from '@react-css/flex';
import { getAsset } from '../../common/assetMap';

const MentorSection = () => {
    return (
        <>
            <Flex className='mentorSection-wrapper'>
                <Flex justifyCenter alignItemsCenter className='mentorSection-half'>
                    <img alt='mentor' src={getAsset("mentor")} className='mentorSection-img' />
                </Flex>
                <Flex column justifyCenter alignItemsCenter className='mentorSection-half'>
                    <div className="mentor-title">
                        Meet the <strong className='mentor'>Mentor</strong>
                    </div>
                    <Flex column alignItemsCenter>
                        <div className="mentor-name">Arumugam Prakash</div>
                        <div className="mentor-pos">Founder & Chief Trader</div>
                        <div className="mentor-firm">
                            Compounding {"("} CPG {")"} Trader Edutech Pvt Ltd.
                        </div>
                        <div className="mentor-desp">
                            "Hi Everyone! I'm Arumugan Prakash, My journey into trading began with the challenges of the COVID-19 pandemic. Initially, I struggled with facing losses and realizing that paid courses available online didn't provide efficient learning. However, I refused to give up and was introduced to SMC concepts. I started learning SMC concepts in depth. Eventually, my efforts paid off, and I became a profitable trader. Encouraged by my success, I decided to share my knowledge with others, teaching online to more than 100 people. where my students are performing well, And now, I'm thrilled to announce the launch of my trading floor, where you can learn and groom alongside me"
                        </div>
                    </Flex>
                </Flex>
            </Flex>
            <div className="mentor-brief">
                “Trading is a lifelong journey,
                <br />
                not a sprint, so embrace the learning curve”
            </div>
        </>
    );
}

export default MentorSection;