import React from 'react';
import './ribbon.css'

const Ribbon = () => {
    return (
      <>
        <div className="ribbon-wrapper">
            <div className="sp1">
                <div className="ribbon-heading">
                    200+
                </div>
                <div className="ribbon-subHeading">
                    Happy Traders
                </div>
            </div>
            <div className="sp2">
                <div className="ribbon-heading">
                    6+
                </div>
                <div className="ribbon-subHeading">
                    Years of Expertise
                </div>
            </div>
            <div className="sp3">
                <div className="ribbon-heading">
                    No.1
                </div>
                <div className="ribbon-subHeading">
                    In offline training
                </div>
            </div>
        </div>
      </>
    );
  }

export default Ribbon;
  