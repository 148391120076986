import React from 'react';

import "./review.css"
import { getAsset } from '../../common/assetMap';
import Flex from '@react-css/flex';
import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperCore, { EffectCoverflow, Pagination, Autoplay } from 'swiper';

import 'swiper/swiper.min.css'
import 'swiper/swiper-bundle.min.css'
import useWidth from '../utils';

SwiperCore.use([Autoplay, EffectCoverflow, Pagination])

const Review = () => {

    const [width] = useWidth()

    const Card = ({ idx }) => {
        return (
            <div className="review-card__container">
                <img src={getAsset("reviews") + "review_" + idx + ".jpg"} alt="" className='review-ss' />
            </div>
        );
    }

    return (
        <>
            <div className="reviews-container">
                <div className="site-heading" style={{ textAlign: "center", marginBottom: "3rem" }}>
                    What our <strong className='student-heading'>Students</strong> {width < 540 ? <br /> : <></>} say about us ?
                </div>
                <Swiper
                    className="swiper_container"
                    effect='coverflow'
                    grabCursor
                    centeredSlides
                    slidesPerView={'auto'}
                    coverflowEffect={{
                        rotate: 0,
                        stretch: 0,
                        depth: 100,
                        modifier: 3,
                        slideShadows: false
                    }}
                    pagination={{ el: '.swiper-pagination', clickable: true }}
                    fadeEffect={{ crossFade: true }}
                    loop
                    autoplay={{ delay: 3000, disableOnInteraction: false }}
                >
                    {
                        Array(11).fill(0)?.map((_, idx) => {
                            return (
                                <SwiperSlide className='review-swiper'>
                                    <Card idx={idx + 1} />
                                </SwiperSlide>
                            )
                        })
                    }
                    <div className="swiper-pagination"></div>
                </Swiper>
            </div>
        </>
    );
}

export default Review;