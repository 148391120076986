import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperCore, { Autoplay, EffectFade } from 'swiper';

import 'swiper/swiper.min.css'
import 'swiper/swiper-bundle.min.css'

import './swiper.css';
import { getAsset } from '../../common/assetMap';

SwiperCore.use([Autoplay, EffectFade])

const SwiperA = () => {
    return (
        <>
            <Swiper effect='fade' fadeEffect={{ crossFade: true }} className="mySwiper" loop autoplay={{ delay: 3000, disableOnInteraction: false }}>
                <SwiperSlide>
                    <img src={getAsset("flrPic4")} alt="pic" style={{ width: "inherit", height: "inherit" }} />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={getAsset("flrPic3")} alt="pic" style={{ width: "inherit", height: "inherit" }} />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={getAsset("flrPic2")} alt="pic" style={{ width: "inherit", height: "inherit" }} />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={getAsset("flrPic1")} alt="pic" style={{ width: "inherit", height: "inherit" }} />
                </SwiperSlide>
            </Swiper>
        </>
    );
}

export default SwiperA;