import React, { useEffect, useState } from 'react';
import { getAsset } from '../../common/assetMap';

import Flex from '@react-css/flex';
import Button from '../btn/button';

import "./hero.css";
import useWidth from '../utils';

const Hero = () => {

    const [innerWidth] = useWidth()

    const [width, setWidth] = useState(innerWidth);

    useEffect(() => {
        if (width !== innerWidth) {
            setWidth(innerWidth)
        }
    }, [width])

    const BgVid = () => {
        return (
            <video className='hero-vid' poster={getAsset("heroBg")} autoPlay muted loop>
                <source src={getAsset(width <= 768 ? "hero_bg_mobile" : "hero_bg")} type="video/mp4" />
            </video>
        )
    }

    return (
        <>
            <div className="video-container">
                <BgVid />
                <Flex alignItemsCenter className="center-hero__wrapper">
                    <Flex column justifyCenter className='hero_container'>
                        <p className="center-hero__text">
                            Join the Masterclass now
                        </p>
                        <Flex justifyCenter className="hero_btn__wrapper">
                            <Button className="hero_btn">
                                Join with Bull Team
                            </Button>
                        </Flex>
                        <p className="hero-desp">
                            "Unlock the secrets to financial success and elevate your trading game – Join our Masterclass for Stock Market Mastery and turn market volatility into your greatest ally!"
                        </p>
                    </Flex>
                </Flex>
            </div>
        </>
    );
}

export default Hero;