import React, { useEffect } from 'react';
import JoinNow from '../marque/joinNow';
import TwMarque from '../marque/twMarque';
import { stocks } from "../marque/stockIdx"
import { forexCrypto } from "../marque/forexCryptoIdx"
import Hero from './hero';
import CoursesPreview from './courses';
import Ribbon from './ribbon';
import OfcShow from './expBlock';
import Curriculum from './curriculum';
import Pricing from '../pricingSection/pricingCard';
import Review from '../reviews/review';
import YtLiveSection from './ytLiveSection';
import MentorSection from './mentorSection';

const HomePage = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="marques">
                <TwMarque sym={stocks} />
                <TwMarque sym={forexCrypto} />
                <JoinNow />
            </div>
            <Hero />
            <CoursesPreview />
            <Ribbon />
            <OfcShow />
            <Curriculum />
            <Pricing />
            <Review />
            <YtLiveSection />
            <MentorSection />
        </>
    );
}

export default HomePage;