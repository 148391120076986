import Flex from '@react-css/flex';
import React from 'react';

import Lottie from 'react-lottie';

import charts_anim from "../../common/animAssets/anim_1_charts.json"

import { getAsset } from '../../common/assetMap';

import "./courses.css"
import useWidth from '../utils';
import Button from '../btn/button';
import { useHistory } from 'react-router';

const CoursesPreview = () => {

    const [width] = useWidth()

    return (
        <>
            <Flex justifySpaceAround alignItemsCenter className='courses-preview__wrapper'>
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: charts_anim,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice"
                        }
                    }}
                    style={{
                        width: (width < 520) ? "300px" : "500px",
                        height: (width < 520) ? "230px" : "400px",
                        margin: 0,
                        padding: 0
                    }}
                    isClickToPauseDisabled
                />
                <div className="courses-preview__right">
                    <div className="site-heading courses-preview__title">
                        <strong>Courses</strong> Offered
                    </div>
                    <Flex alignItemsCenter className='course_box'>
                        <CourseBox
                            img="stm_icon"
                            text={"Stocks"}
                         />
                         <CourseBox
                            img="forex_icon"
                            text={"Forex"}
                            isCenter
                         />
                         <CourseBox
                            img="crypto_icon"
                            text={"Crypto"}
                         />
                    </Flex>
                    <Flex justifyCenter>
                        <Button className='toRoadmap-btn'>
                            Checkout our roadmap
                        </Button>
                    </Flex>
                </div>
            </Flex>
        </>
    );
}

const CourseBox = ({ img, text, isCenter = false }) => {
    const history = useHistory()
    return (
      <>
        <div className={`course_box__container ${isCenter && "course_box__center"}`} onClick={() => {
            history.push(`/aboutus?key=${text.toLowerCase()}`)
        }}>
          <div className="course_box__wrapper">
            <img
              loading="lazy"
              src={getAsset(img)}
              className="course_box__icon"
            />
            <div className="course_box__text">{text}</div>
          </div>
        </div>
      </>
    );
}

export default CoursesPreview;