import React from 'react';

import '../node_modules/bootstrap/dist/css/bootstrap.css';
import '../node_modules/bootstrap/dist/css/bootstrap-grid.css';

import '../node_modules/rc-collapse/assets/index.css';

import "./App.css";

import "./root.css";

import './strong.scss';

// router 
import { Switch, Route, Redirect } from 'react-router-dom';

// components
import NavBar from './components/navbar/navbar';
import Footer from './components/footer/footer';

import HomePage from './components/Home/home';
import Roadmap from './components/roadmap/roadmap';
import Courses from './components/courses/courses';
import Aboutus from './components/aboutus/aboutus';

const App = () => {
  return (
    <>
      <NavBar />
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/home" component={HomePage} />
        <Route exact path="/roadmap" component={Roadmap} />
        <Route exact path="/courses" component={Courses} />
        <Route exact path="/aboutus" component={Aboutus} />
        <Route exact path="*" render={() => <Redirect to="/" />} />
      </Switch>
      <Footer />
    </>
  );
}

export default App;