import React from 'react';
import './button.scss'
import { useHistory } from 'react-router';

const Button = ({ children, style = {}, className = "", onClick = () => {} }) => {

    const history = useHistory()

    const onBtnClick = () => {
        if (["join with bull team"].includes(children.toLowerCase())) {
            window.open("https://forms.gle/onftBUbs8xLQCG4d7", "_blank")
        } else if (["checkout our roadmap"].includes(children.toLowerCase())) {
            history.push("/roadmap")
        } else if (["what we offer for you", "learn more about courses", "checkout our courses"].includes(children.toLowerCase())) {
            history.push("/courses")
        } else if (["learn more"].includes(children.toLowerCase())) {
            history.push("/aboutus")
        }
    }

    return (
        <>
            <button onClick={onBtnClick} type="button" className={`pulse back ${className}`} style={{...style}}>
                {children}
            </button>
        </>
    );
}

export default Button;