import React, { useState } from 'react';

import './collapse.css';

import Collapse from "rc-collapse"

import { MdExpandMore } from "react-icons/md";
import { MdExpandLess } from "react-icons/md";

const Panel = Collapse.Panel

const Accordian = ({ content, placeTitleCenter = false, noPadding = false, activeKey = "0" }) => {

    console.log(activeKey, '#$@#')

    return (
        <div className='accordian-wrapper'>
            <Collapse
                accordian
                defaultActiveKey={activeKey}
                className={`accordian-container ${placeTitleCenter && "accordian-canPlaceTextCenter"}`}
                expandIcon={({ isActive }) => {

                    return (
                        <>
                            {isActive ? <MdExpandLess className='accordian-icon' /> : <MdExpandMore className='accordian-icon' />}
                        </>
                    )
                }}
            >
                {content?.map(({ title, key, content }) => {
                    return (
                        <>
                            <Panel
                                id={key}
                                showArrow={true}
                                header={title}
                                headerClass={`accordian-panel accordian-panel__bottom`}
                            >
                                <div className={`${noPadding ? "" : "accordian-content"}`}>
                                    {content}
                                </div>
                            </Panel>
                        </>
                    )
                })}
            </Collapse>
        </div>
    );
}

export default Accordian;