import Flex from '@react-css/flex';
import React from 'react';
import Marquee from "react-fast-marquee";

import './marque.css';

const JoinNow = () => {
    return (
        <div className="joinNow-wrapper">
            <Marquee autoFill pauseOnHover direction='left'>
                <>
                    <Flex className="joinNow-container">
                        <p className='joinNow-text'>
                            Join the course now <span className="blinking">@20% off!</span> for first 20 students
                        </p>
                        <p className="joinNow-btn" onClick={() => {
                            window.open("https://forms.gle/onftBUbs8xLQCG4d7", "_blank")
                        }}>
                            Join now!
                        </p>
                    </Flex>
                </>
            </Marquee>
        </div>
    );
}

export default JoinNow;