import React from 'react';

import "./ytLiveSection.css";

import Flex from '@react-css/flex';

const YtLiveSection = () => {

    return <></>

    return (
        <>
            <Flex column justifyCenter className="ytLive-wrapper">
                <div className="ytSection-title">
                    Catch our previous <strong className='liveTrade'>Live Trades</strong> on Youtube
                </div>
                <Flex alignItemsCenter justifyCenter className="ytIframe-wrapper">
                    <iframe className="ytIframe-container" src="https://www.youtube.com/embed/ExL8jClroLQ?si=jpM9NM4Br65dA979&autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </Flex>
            </Flex>
        </>
    );
}

export default YtLiveSection;