import React from 'react';

import "./expBlock.css";
import Flex from '@react-css/flex';
import Button from '../btn/button';
import { getAsset } from '../../common/assetMap';
import SwiperA from '../swiper/swiper';

const OfcShow = () => {
    return (
        <div className='ofcShow-wrapper'>
            <p className="site-heading ofcShow-title">
                Online / Offline <strong>Training</strong>
            </p>
            <Flex justifySpaceAround className="ofcShow-container">
                <Flex column className='o1'>
                    <Flex className="o1-container">
                        <p className="ofcTraining-desp">

                            "Our one-month intensive course teaches you smart money concepts used by pro-traders. With our funded trading program, wherein we will initially provide trading funds, you can prove your talent and gain access to larger funds. Plus, get a completion certificate,{"  "} join our live trading floor and learn from experienced traders in real time"
                        </p>
                    </Flex>
                    <Flex justifyCenter>
                        <Button className='ofcTraining-btn'>
                            What we offer for you
                        </Button>
                    </Flex>
                </Flex>
                <Flex className='o2'>
                    <img src={getAsset("flrVector")} className="ofcBgVector" />
                    <div className="ofcWiper_wrapper">
                        <SwiperA />
                    </div>
                </Flex>
            </Flex>
        </div>
    );
}

export default OfcShow;