import React, { useEffect } from 'react';

import './aboutus.css';
import Flex from '@react-css/flex';
import { getAsset } from '../../common/assetMap';
import Accordian from '../collapse/collapse';
import Pricing from '../pricingSection/pricingCard';
import MentorSection from '../Home/mentorSection';
import { useHistory } from 'react-router';

const Aboutus = () => {

    const history = useHistory()

    let scrollTo = history?.location?.search

    useEffect(() => {
        if (scrollTo === '') {
            window.scrollTo(0, 0)
        } else {
            try {
                window.scrollTo(0, 0)

                scrollTo = scrollTo?.split("=")[1]
                const anchor = document.querySelector(`#${scrollTo}`)
                anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
                
            } catch (e) {
                console.error(e)
            }
        }
    }, []) 
    const AboutCard = ({ icon, note }) => {

        return (
            <>
                <Flex column justifySpaceAround alignItemsCenter className="aboutCard-base">
                    <img src={icon} alt="" className="aboutus-icon" />
                    <p className="aboutus-note">
                        {note}
                    </p>
                </Flex>
            </>
        )
    }

    const faqs = [
        {
            "key": 1,
            "title": "I’m new to trading, can I able to trade on my own ?",
            "content": (
                <p className='faq-answers'>
                    Yes, we do start teaching people how to trade the stock market from the ground up, teaching them everything from the fundamentals to advanced level strategies so they can make a consistent income from it.
                </p>
            )
        },
        {
            "key": 2,
            "title": "The session are conducted in which language ?",
            "content": (
                <p className='faq-answers'>
                    We teach in tamil and english.
                </p>
            )
        },
        {
            "key": 3,
            "title": "What is the duration of this program ?",
            "content": (
                <p className='faq-answers'>
                    It's a 1-month mentorship program.
                </p>
            )
        },
        {
            "key": 4,
            "title": "Is certificate provided ?",
            "content": (
                <p className='faq-answers'>
                    Yes, we offer certificates to students who successfully complete the course.
                </p>
            )
        },
        {
            "key": 5,
            "title": "What is the session timings ?",
            "content": (
                <p className='faq-answers'>
                    We will teach from morning 9.30am to 3pm on weekdays.
                </p>
            )
        }
    ]

    return (
        <>
            <img src={getAsset("bgAbstract")} alt="" className="aboutus-abstract aboutus-abstract-0" />
            {/* <img src={getAsset("bgAbstract")} alt="" className="aboutus-abstract aboutus-abstract-3" />
            <img src={getAsset("bgAbstract")} alt="" className="aboutus-abstract aboutus-abstract-4" /> */}
            <div className="aboutus-wrapper">
                <div className="site-heading" style={{ textAlign: "center" }}>
                    <strong className="aboutus-title">Learning phase</strong>
                </div>
                <div className="aboutus-desp">
                    "Upon joining our institute, we prioritize building a solid foundation for our students by imparting in-depth knowledge of fundamentals and advanced trading strategies. We believe in a hands-on approach, and to facilitate practical learning, we offer funding for our students to implement various methodologies in real market scenarios. As their momentum grows, we elevate the learning experience by organizing competitions and hackathons, fostering a spirit of healthy competition and innovation. The top performers in these challenges are not only recognized but also presented with the exciting opportunity to work with us in a live trading environment, where additional funds are provided to further amplify their success and professional growth."
                </div>
                <div className="site-heading" style={{ textAlign: "center" }}>
                    <strong className="aboutus-title">Peaks of joining</strong> with us
                </div>
                <div className="aboutus-cards__container">
                    <Flex justifySpaceAround row flexWrap='wrap' style={{ width: "100%" }}>
                        <AboutCard icon={getAsset("doubtIcon")} note="Live doubt clearing sessions" />
                        <AboutCard icon={getAsset("strategiesIcon")} note="Proven Strategies" />
                        <AboutCard icon={getAsset("liveIcon")} note="Live trade with us via youtube live" />
                    </Flex>
                    <Flex justifyCenter row flexWrap='wrap' style={{ width: "100%" }}>
                        <AboutCard icon={getAsset("communityIcon")} note="Access to our private community" />
                        <AboutCard icon={getAsset("floorIcon")} note="Offline trading floor" />
                    </Flex>
                </div>
                <MentorSection />
                <img src={getAsset("bgAbstract")} alt="" className="aboutus-abstract aboutus-abstract-1" />
                <img src={getAsset("bgAbstract")} alt="" className="aboutus-abstract aboutus-abstract-2" />
                <div className="faq-section">
                    <div className="site-heading" style={{ textAlign: "center" }}>
                        Frequently Asked <strong className='faq'>Questions</strong>
                    </div>
                    <Accordian content={faqs} noPadding />
                </div>
                <Pricing />
                <div className="articles-section">
                    <div className="site-heading" style={{ textAlign: "center" }}><strong>Articles</strong></div>
                    <Flex justifySpaceAround row flexWrap='wrap' style={{ width: "100%" }}>
                        <Flex column justifySpaceAround className="article-container" id='stocks'>
                            <div className="article-title">
                                What is Stock Market Trading ?
                            </div>
                            <div className="article-ref">
                                <iframe className="article-ref" src="https://www.youtube.com/embed/p7HKvqRI_Bo?si=8zvAee0UOxArIftL&autoplay=1&mute=1&loop=1&controls=0&list=PLhbM6lHkkhlauF9GTNpH37jREox12AvKn&index=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                        </Flex>
                        <Flex column justifySpaceAround className="article-container" id='forex'>
                            <div className="article-title">
                                What is Forex Market Trading ?
                            </div>
                            <div className="article-ref">
                                <iframe className="article-ref" src="https://www.youtube.com/embed/M_pQxkYsyh4?si=NvUFvLr7whZ-Kb8Q&loop=1&autoplay=1&mute=1&controls=0&list=PLhbM6lHkkhlbrIhF7kLP-rRG1ZWOwqEsY&index=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                        </Flex>
                        <Flex column justifySpaceAround className="article-container" id='crypto'>
                            <div className="article-title">
                                What is Crypto Market Trading ?
                            </div>
                            <div className="article-ref">
                                <iframe className="article-ref" src="https://www.youtube.com/embed/XWjsA2XGDzA?si=6y3pmLXh08w2WF6r&autoplay=1&mute=1&loop=1&controls=0&list=PLhbM6lHkkhlYcqiD8BzAh3YII8G39u-GZ&index=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                        </Flex>
                    </Flex>
                </div>
            </div>
        </>
    );
}

export default Aboutus;