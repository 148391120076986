export const forexCrypto = [
    {
      "description": "",
      "proName": "FX:USDCAD"
    },
    {
      "description": "",
      "proName": "FX:EURUSD"
    },
    {
      "description": "",
      "proName": "FX:AUDUSD"
    },
    {
      "description": "",
      "proName": "FX:USDCHF"
    },
    {
      "description": "",
      "proName": "FXOPEN:XAUUSD"
    },
    {
      "description": "",
      "proName": "FXOPEN:XAGUSD"
    },
    {
      "description": "",
      "proName": "BITSTAMP:BTCUSD"
    },
    {
      "description": "",
      "proName": "BITSTAMP:ETHUSD"
    },
    {
      "description": "",
      "proName": "CAPITALCOM:OIL_CRUDE"
    }
  ]