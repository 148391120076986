import React from 'react';
import { TickerTape } from "react-ts-tradingview-widgets";

const TwMarque = ({ sym }) => {

    return (
        <>
            <TickerTape copyrightStyles={{ parent: { display: "none" } }} isTransparent={true} displayMode={true} showSymbolLogo={true} colorTheme="light" symbols={sym} />
        </>
    );
}

export default TwMarque;