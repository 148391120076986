import React, { useEffect, useState } from 'react';
import Accordian from '../collapse/collapse';
import Lottie from 'react-lottie';

import teachingAnim from '../../common/animAssets/teachingAnim.json'
import './courses.css'
import Flex from '@react-css/flex';
import useWidth from '../utils';
import Review from '../reviews/review';
import Pricing from '../pricingSection/pricingCard';
import { getAsset } from '../../common/assetMap';
import { useHistory } from 'react-router';

const Courses = () => {

    const [width] = useWidth()

    const history = useHistory()

    let scrollTo = history?.location?.search

    const [defaultKey, setDefaultKey] = useState(scrollTo === '' ? "0" : scrollTo?.split("=")[1]?.split("-")[[1]])

    useEffect(() => {
        if (scrollTo === '') {
            window.scrollTo(0, 0)
        } else {
            try {
                window.scrollTo(0, 0)

                scrollTo = scrollTo?.split("=")[1]
                const anchor = document.querySelector(`#${scrollTo}`)
                anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })

                scrollTo = scrollTo?.split("-")[1]
                setDefaultKey(scrollTo)
            } catch (e) {
                console.error(e)
            }
        }
    }, [])

    const modules = [
        {
            key: "week-0",
            title: "( Week - 1 ) Basics of market",
            content: (
                <>
                    <ul>
                        <li>Basics of Indian Market (Futures & Options)</li>
                        <li>Basics of Forex and Crypto Market</li>
                        <li>Basics of Candle Sticks</li>
                        <li>Basics of Price Action</li>
                        <li>Basics of SMC & ICT</li>
                    </ul>
                </>
            )
        },
        {
            key: "week-1",
            title: "( Week - 2 ) Introduction to technical analysis",
            content: (
                <>
                    <ul>
                        <li>Market Structure - I</li>
                        <li>Crack the code of Support & Resistance</li>
                        <li>Decode Chart Patterns</li>
                        <li>Live Trading Session</li>
                        <li>Live Trading Session</li>
                    </ul>
                </>
            )
        },
        {
            key: "week-2",
            title: "( Week - 3 ) SMC - Core concepts",
            content: (
                <>
                    <ul>
                        <li>Market Structure - II</li>
                        <li>Supply & Demand</li>
                        <li>Imbalance & FVG</li>
                        <li>Blocks Type</li>
                        <li>Identify high probability setups by <span className='bold'>FVG & IMB & IPA</span></li>
                        <li>Live Trading Session</li>
                    </ul>
                </>
            )
        },
        {
            key: "week-3",
            title: "( Week - 4 ) Mastering the strategies",
            content: (
                <>
                    <ul>
                        <li>Liquidity & Types</li>
                        <li>Inducement & IPC</li>
                        <li>PD Arrays</li>
                        <li>
                            Power of three
                        </li>
                        <li>Live Trading Session</li>
                    </ul>
                </>
            )
        },
        {
            key: "week-4",
            title: "( Week - 5 ) Mastering the advanced strategies",
            content: (
                <>
                    <ul>
                        <li>Multi Timeframe Analysis</li>
                        <li>Entry Types</li>
                        <li>Trade Implementation</li>
                        <li>Trade Implementation</li>
                        <li>Certificates</li>
                    </ul>
                </>
            )
        }
    ]

    return (
        <>
            <img src={getAsset("bgAbstract")} alt="" className="roadmap-abstract roadmap-abstract-1" style={{ top: 0 }} />
            <img src={getAsset("bgAbstract")} alt="" className="roadmap-abstract roadmap-abstract-1" />
            {/* <img src={getAsset("bgAbstract")} alt="" className="roadmap-abstract roadmap-abstract-2" style={{ right: "-8rem", top: "13rem"}} /> */}
            {/* <img src={getAsset("bgAbstract")} alt="" className="roadmap-abstract roadmap-abstract-2" style={{ right: "-8rem", top: "60rem" }} /> */}
            <div className="courses-section__wrapper">
                <Flex justifySpaceAround flexWrap='wrap' className="training-container">
                    <Flex column justifyStart className='c1'>
                        <Flex className="c1-container">
                            <div className="site-heading" style={{ textAlign: "center" }}>
                                How we <strong className='course'>Teach ?</strong>
                            </div>
                        </Flex>
                        <Flex justifyCenter>
                            <p className="training-desp">
                                "Ready to step into the trading world with more than just knowledge ? Join our academy & gain immediate access to our <span className="teach-hightlight">
                                    Funded Trading accounts
                                </span>, skip initial capital hurdles & trade with our resource"
                            </p>
                            <div>
                            </div>
                        </Flex>
                    </Flex>
                    <Flex className='c2' alignItemsCenter>
                        <Lottie
                            options={{
                                loop: true,
                                autoplay: true,
                                animationData: teachingAnim,
                                rendererSettings: {
                                    preserveAspectRatio: "xMidYMid slice"
                                }
                            }}
                            style={{
                                width: width < 530 ? "380px" : "530px",
                                height: width < 530 ? "300px" : "430px",
                                margin: 0,
                                padding: 0,
                            }}
                            isClickToPauseDisabled
                        />
                    </Flex>
                </Flex>
            </div>
            <div className="courses-column">
                <div className="site-heading" style={{ textAlign: "center" }}>
                    Our <strong className="course">Courses</strong>
                </div>
                <Accordian
                    placeTitleCenter
                    content={modules}
                    activeKey={defaultKey}
                />
            </div>
            <Review />
            <Pricing />
        </>
    );
}

export default Courses;