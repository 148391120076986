import Flex from "@react-css/flex";
import React from "react";

import './curriculum.css';
import Button from "../btn/button";
import { useHistory } from "react-router";

const Curriculum = () => {

    const history = useHistory()

    const handleClick = (idx) => {
        history.push(`/courses?key=week-${idx - 1}`)
    };

    return (
        <>
            <Flex column className="curriculum-wrapper">
                <p className="site-heading" style={{ textAlign: "center" }}>
                    <strong className="course">Course</strong> Curriculum
                </p>
                <div className="course-container">
                    <div className="course-div" onClick={() => handleClick(1)}>
                        ( Week - 1 ) Basics of market
                    </div>
                    <div className="course-divider" aria-hidden="true"></div>
                    <div className="course-div" onClick={() => handleClick(2)}>
                        ( Week - 2 ) Introduction to technical analysis
                    </div>
                    <div className="course-divider" aria-hidden="true"></div>
                    <div className="course-div" onClick={() => handleClick(3)}>
                        ( Week - 3 ) SMC - Core concepts
                    </div>
                    <div className="course-divider" aria-hidden="true"></div>
                    <div className="course-div" onClick={() => handleClick(4)}>
                        ( Week - 4 ) Mastering the strategies
                    </div>
                    <div className="course-divider" aria-hidden="true"></div>
                    <div className="course-div" onClick={() => handleClick(5)}>
                        ( Week - 5 ) Mastering the advanced strategies
                    </div>
                </div>
                <Flex justifyCenter>
                    <Button className="lm-courses-btn">
                        Learn more about courses
                    </Button>
                </Flex>
            </Flex>
        </>
    );
}

export default Curriculum;