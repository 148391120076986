export const stocks = [
    {
      "description": "",
      "proName": "NSE:NIFTY"
    },
    {
      "description": "",
      "proName": "NSE:BANKNIFTY"
    },
    {
      "description": "",
      "proName": "NSE:CNXMIDCAP"
    },
    {
      "description": "",
      "proName": "BSE:SENSEX"
    },
    {
      "description": "",
      "proName": "BSE:HDFCBANK"
    },
    {
      "description": "",
      "proName": "BSE:ITC"
    },
    {
      "description": "",
      "proName": "BSE:SBIN"
    },
    {
      "description": "",
      "proName": "BSE:TCS"
    },
    {
      "description": "",
      "proName": "BSE:INFY"
    },
    {
      "description": "",
      "proName": "BSE:WIPRO"
    }
  ]