const assets = {
    "logo": "logo.svg",
    "hero_bg": "bg_vid_c3.mp4",
    "hero_bg_mobile": "bg_vid_vert_c3.mp4",
    "charts_anim": "anim_1_charts.json",
    "stm_icon": "stm.svg",
    "forex_icon": "forex.svg",
    "crypto_icon": "bitcoin.svg",
    "flrVector": "flrVector.svg",
    "mentor": "mentorPic.png",
    "roadmapBgPath": "bgPath.svg",
    "roadmapMobileBgPath": "straight_bg.svg",

    "wa": "whatsapp.svg",
    "insta": "instagram.svg",
    "x": "x.svg",
    "yt": "yt.svg",
    "telegram": "telegram.svg",

    "bgAbstract": "bgAbtrast.svg",
    "heroBg": "vid_bg.jpg",

    "flrPic1": "ph1.jpg",
    "flrPic2": "pic2.jpg",
    "flrPic3": "pic3.jpg",
    "flrPic4": "pic4.jpg",

    "doubtIcon": "doubts.svg",
    "strategiesIcon": "strategies.svg",
    "liveIcon": "live.svg",
    "communityIcon": "community.svg",
    "floorIcon": "tradingfloor.svg",

    "reviews": "reviews/",
}

export const getAsset = (name) => {
    const cf = "https://d12orny839qmus.cloudfront.net"

    return `${cf}/${assets[name]}`
}