import React, { useEffect } from 'react';

import "./roadmap.css";
import Flex from '@react-css/flex';
import { getAsset } from '../../common/assetMap';

import joinAnim from "../../common/animAssets/join_anim.json"
import lpAnim from "../../common/animAssets/lp_anim.json"
import hackathonAnim from "../../common/animAssets/hackathon_anim.json"
import moneyAnim from "../../common/animAssets/moneyAnim.json"
import useWidth from '../utils';

import Lottie from 'react-lottie';
import Button from '../btn/button';
import { TypeAnimation } from 'react-type-animation';


const Roadmap = () => {

    const [width] = useWidth()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []) 

    const PlanningCardLayout = ({ img, heading, btnText = "", text }) => {
        return <>
            <Flex gap={4} justifyCenter alignItemsCenter className='event-plainCard--container'>
                <Flex column alignItems='center' justifyCenter className='event-plainCard--innerCard'>
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: img,
                            rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice"
                            }
                        }}
                        style={{
                            width: "130px",
                            height: "130px",
                            margin: 0,
                            padding: 0,
                        }}
                        isClickToPauseDisabled
                    />
                    <p className="event-plainCard--heading" style={{ marginBottom: btnText === "" ? 0 : "1rem" }}>
                        {heading}
                    </p>
                    <>
                        {
                            (btnText === "") ?
                                <p className="event-plainCard--subHeading">{"("} {text} {")"}</p>
                                : <Button style={{ width: "200px", fontSize: "16px", fontWeight: "800", marginBottom: "1.5rem" }}>
                                    {btnText}
                                </Button>
                        }
                    </>
                </Flex>
            </Flex>
        </>
    }

    return (
        <>
            <img src={getAsset("bgAbstract")} alt="" className="roadmap-abstract roadmap-abstract-0" />
            <img src={getAsset("bgAbstract")} alt="" className="roadmap-abstract roadmap-abstract-1" />
            <img src={getAsset("bgAbstract")} alt="" className="roadmap-abstract roadmap-abstract-2" />
            <img src={getAsset("bgAbstract")} alt="" className="roadmap-abstract roadmap-abstract-3" />
            <img src={getAsset("bgAbstract")} alt="" className="roadmap-abstract roadmap-abstract-4" />
            <p className="site-heading courses-heading">Our <strong className='roadmap'>Roadmap</strong></p>
            <Flex justifyCenter style={{ marginBottom: "1rem" }}>
                <Flex justifyEnd style={{ width: "50%" }}>
                    <p className='typing-1'>
                        More than trading:
                    </p>
                </Flex>
                <Flex justifyStart style={{ width: "50%" }}>
                    <TypeAnimation
                        sequence={[
                            // Same substring at the start will only be typed once, initially
                            "Learn Together",
                            100,
                            "Connect Together",
                            100,
                            "Succeed Together",
                            100,
                        ]}
                        speed={50}
                        className='typing-2'
                        repeat={Infinity}
                    />
                </Flex>
            </Flex>
            <div row className="appBody event-planningPath--wrapper">
                <Flex justifyCenter className="event-planning--bgWrapper">
                    <div className="event-planning-pathWay">
                        <Flex className="event-planning--evt1">
                            <Flex className="event-planning--evt1Left">
                                <PlanningCardLayout img={joinAnim} heading={"Joining with us"} btnText='Checkout our courses' />
                            </Flex>
                            <Flex justifyCenter className="event-planning--evt1Right">
                                <Flex className="event-planning--evtRightContainer">
                                    <p className="event-planning-text event-planning--rightMargin">
                                        <div className="event-planning--upperDash"></div>
                                        Join our exclusive masterclass batch, which is available both offline and online.                                    </p>
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex className="event-planning--evt2">
                            <Flex justifyCenter className="event-planning--evt1Left">
                                <p className="event-planning-text event-planning--rightMargin">
                                    <div className="event-planning--upperDash"></div>
                                    Learn the trading market secrets that will allow you to print your own money.
                                </p>
                            </Flex>
                            <Flex className="event-planning--evt1Right">
                                <Flex className="event-planning--evtRightContainer">
                                    <PlanningCardLayout img={lpAnim} heading={"Learning Phase"} btnText='Learn more' />
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex className="event-planning--evt1">
                            <Flex className="event-planning--evt1Left">
                                <PlanningCardLayout img={moneyAnim} heading={"Get Funded Account"} text={"In timeline"} />
                            </Flex>
                            <Flex justifyCenter className="event-planning--evt1Right">
                                <Flex className="event-planning--evtRightContainer">
                                    <p className="event-planning-text event-planning--rightMargin">
                                        <div className="event-planning--upperDash"></div>
                                        We provide our funds for your trades, so you can kickstart your trading journey.
                                    </p>
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex alignItemsCenter justifyCenter className="event-planning--evt2">
                            <Flex justifyCenter className="event-planning--evt1Left">
                                <p className="event-planning-text">
                                    <div className="event-planning--upperDash"></div>
                                    Win our hack-a-thon, and Get our one-month mentorship for free!
                                </p>
                            </Flex>
                            <Flex className="event-planning--evt1Right">
                                <Flex className="event-planning--evtRightContainer">
                                    <PlanningCardLayout img={hackathonAnim} heading={"Hack-a-thon"} text={"Upcoming"} />
                                </Flex>
                            </Flex>
                        </Flex>
                    </div>
                    <img src={getAsset((width < 768) ? "roadmapMobileBgPath" : "roadmapBgPath")} alt="bg" className={`event-planning--bgContainer ${(width < 768) && "event-planning--bgMobileContainer"}`} />
                </Flex>
            </div>
        </>
    );
}

export default Roadmap;