import React from 'react';

import Lottie from 'react-lottie';

import anim from "../../common/animAssets/pricingAnim.json"
import Flex from '@react-css/flex';
import useWidth from '../utils';

import "./pricingCard.css"
import "./pricingBtn.scss";

const PricingCard = () => {

    return (
        <>
            <header className={`main-container`}>
                <h1 className="title">
                    <span className="blinking">Buy our course @20% off!</span>
                </h1>
                <p className="description">
                    Book offline class <br /> for{" "}
                    <br />
                    <span className='cancelled-amt' aria-label="price in rupees" aria-role="text">
                        ₹25000/-
                    </span>
                </p>
                <p className="discount-text">Buy on discount of</p>
                <div className="discount-price">
                    <strong className='pricing-amt__strong'>₹19999/-</strong>
                </div>
                <span className="note-pricing">
                    * for first 20 students only applicable!
                </span>
                <p className="description" style={{ marginTop: "1rem", marginBottom: 0 }}>
                    Book online class <br /> for{" "}
                </p>
                <div className="discount-price" style={{ marginTop: 0 }}>
                    <strong className='pricing-amt__strong'>₹3999/-</strong>
                </div>
                <Flex column className="note-pricing" style={{ textAlign: "left", marginLeft: "20px", marginTop: "5px" }}>
                    <div className="pts">* 5-week comprehensive course!</div>
                    <div className="pts">* Access to one-month live trading floor!</div>
                    <div className="pts">* Access to one-month private community!</div>
                    <div className="pts">* Life time mentor support!</div>
                </Flex>
                <Button className="join-button">
                    Join with Bull Team
                </Button>
            </header>
        </>
    );
}

const Pricing = () => {

    const [width] = useWidth()

    return (
        <>
            <div className="pricing-container">
                <p className="site-heading" style={{ textAlign: "center" }}>
                    Why are you <strong className='waiting'>waiting</strong> ?
                </p>
                <Flex className="pricing-wrapper">
                    <Flex justifyCenter className="pricing-card">
                        <PricingCard />
                    </Flex>
                    <Flex alignItemsCenter justifyCenter className='pricing-card_anim'>
                        <Lottie
                            options={{
                                loop: true,
                                autoplay: true,
                                animationData: anim,
                                rendererSettings: {
                                    preserveAspectRatio: "xMidYMid slice"
                                }
                            }}
                            style={{
                                width: (width < 520) ? "300px" : "600px",
                                height: (width < 520) ? "300px" : "600px",
                                margin: 0,
                                padding: 0
                            }}
                            isClickToPauseDisabled
                        />
                    </Flex>
                </Flex>
            </div>
        </>
    );
}


const Button = ({ children, style = {}, className = "", onClick = () => {} }) => {

    const onBtnClick = () => {
        if (["join with bull team"].includes(children.toLowerCase())) {
            window.open("https://forms.gle/onftBUbs8xLQCG4d7", "_blank")
        }
    }

    return (
        <>
            <button onClick={onBtnClick} type="button" className={`pulse-pricing back ${className}`} style={{...style}}>
                {children}
            </button>
        </>
    );
}

export default Pricing;