import React from 'react';

import './footer.css'
import { getAsset } from '../../common/assetMap';
import Flex from '@react-css/flex';

const Footer = () => {

    const handleClick = (idx) => {

        if (idx === 1) {
            const msgText = "Hello! Enquiry for course"

            window.open(`https://api.whatsapp.com/send?phone=917702867817&text=${msgText}&lang=en`, "_blank")
        } else if (idx === 2) {
            window.open(`https://www.instagram.com/compounding_tamil_trader`, "_blank")
        } else if (idx === 3) {
            window.open(`https://x.com/Compounding011`, "_blank")
        } else if (idx === 4) {
            window.open(`https://www.youtube.com/@Compoundingtraders`, "_blank")
        } else if (idx === 5) {
            window.open(`https://t.me/+djnUwG5ZQYVjYmFl`, "_blank")
        }

        console.log("#@$#@$")
    }

    return (
        <>
            <footer>
                <Flex column alignItemsCenter justifyCenter>
                    <img className='logo footer-logo' src={getAsset("logo")} alt="logo" />
                    <Flex justifySpaceBetween className='social-icons__wrapper'>
                        <img src={getAsset("wa")} alt="sm" className='social-icons' onClick={() => handleClick(1)} />
                        <img src={getAsset("yt")} alt="sm" className='social-icons' onClick={() => handleClick(2)} />
                        <img src={getAsset("x")} alt="sm" className='social-icons' onClick={() => handleClick(3)} />
                        <img src={getAsset("insta")} alt="sm" className='social-icons' onClick={() => handleClick(4)} />
                        <img src={getAsset("telegram")} alt="sm" className='social-icons' onClick={() => handleClick(5)} />
                    </Flex>
                    <Flex className="cr-text">
                        <Flex className="av-text" justifyCenter>
                            This site is crafted by <div onClick={() => { window.open(`https://api.whatsapp.com/send?phone=918610047522&lang=en`, "_blank") }} className='av'>Aadarsh velu</div>
                        </Flex>
                        © Copyright - Compounding {"("} CPG {")"} Trader Edutech Pvt Ltd.
                    </Flex>
                </Flex>
            </footer>
        </>
    );
}

export default Footer;